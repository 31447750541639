
    import { LoginSetResetPasswordBody } from "@/apiManager/login/loginData";
    import { ErrorServerCodeEnum } from "@/apiManager/_common/enums";
    import RoutesEnum from "@/router/routesEnum";
    import ModalInformativePayload from "@/store/modules/modalInformative/models";
    import { AxiosError } from "axios";
    import JwtDecode from "jwt-decode";
    import { Component, Prop, Vue } from "vue-property-decorator";

    @Component
    export default class ResetPassword extends Vue
    {
        //#region Components
        //#endregion

        //#region Props
        @Prop({ type: String, default: "" }) private readonly token!: string;
        //#endregion

        //#region Data
        private m_ResetPasswordInProgress: boolean = false;

        private m_NewPassword: string = "";
        private m_ConfirmNewPassword: string = "";

        private m_ErrorNewPassword: boolean = false;
        private m_ErrorConfirmNewPassword: boolean = false;
        private m_ErrorFromApiPassword: boolean = false;

        private m_ErrorFromApiCode: number = 0;
        private m_TokenInvalid: boolean = false;
        //#endregion

        //#region Getters
        get getEmail(): string
        {
            if (this.token.length > 0)
            {
                try
                {
                    let tokenDecoded: any = JwtDecode(this.token);
                    return tokenDecoded.Email ?? "";
                }
                catch
                {
                    return "";
                }
            }
            else
            {
                return "";
            }
        }

        getErrorFromApiMessage(): string
        {
            switch(this.m_ErrorFromApiCode)
            {
                case ErrorServerCodeEnum.ResetPasswordEmailInvalid:
                    return this.i18n(this.getRezToursLanguageEnum.errorRecoverPasswordEmailInvalid);
                case ErrorServerCodeEnum.ActionFailed:
                    return this.i18n(this.getRezToursLanguageEnum.errorUnableToSendEmail);
                default:
                    return this.i18n(this.getRezToursLanguageEnum.serverErrorMsg);
            }
        }
        //#endregion

        //#region Functions
        //#endregion

        //#region Events
        async onResetPasswordClick()
        {
            if (this.m_ResetPasswordInProgress)
            {
                return;
            }

            this.m_ErrorNewPassword = false;
            this.m_ErrorConfirmNewPassword = false;
            this.m_ErrorFromApiCode = 0;
            this.m_ErrorFromApiPassword = false;

            if (this.m_NewPassword.length === 0)
            {
                this.m_ErrorNewPassword = true;
                return;
            }

            if (this.m_NewPassword !== this.m_ConfirmNewPassword)
            {
                this.m_ErrorConfirmNewPassword = true;
                return;
            }

            try
            {
                let body: LoginSetResetPasswordBody = new LoginSetResetPasswordBody(this.m_NewPassword, this.token);
                await this.$apiManager.login.setResetPasswordAsync(body);

                let payload = new ModalInformativePayload.Mutations.DisplayModalInformative("", this.i18n(this.getRezToursLanguageEnum.resetPasswordSuccess));
                this.$store.commit(payload);
                this.$router.push({name: RoutesEnum.Package});
            }
            catch(ex)
            {
                this.m_ErrorFromApiCode = (ex as AxiosError)?.response?.data?.codeError ?? 0;
                this.m_ErrorFromApiPassword = true;
            }
            finally
            {
                this.m_ResetPasswordInProgress = false;
            }
        }
    //#endregion

    //#region Hooks

    created()
    {
        try
        {
            let tokenDecoded: any = JwtDecode(this.token);
            if (Date.now() >= tokenDecoded.exp * 1000)
            {
                this.m_TokenInvalid = true;
            }
        }
        catch
        {
            this.m_TokenInvalid = true;
        }

    }
    //#endregion

    //#region Watch
    //#endregion
    }
